import React from 'react'

function ErrorPage() {
    return (
        <div>
            This page does not exist.
        </div>
    )
}

export default ErrorPage