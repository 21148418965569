import React from 'react'
import styled, { keyframes, css } from 'styled-components'

import utw from '../../assets/images/utw.png'

const utwAnimation = keyframes`
 0% { opacity: 1; }
 5% { opacity: 0; }
 10% { opacity: 1; }
 15% { opacity: 0; }
 20% { opacity: 1; }
 25% { opacity: 0; }
 30% { opacity: 1; }
 35% { opacity: 0; }
 40% { opacity: 1; }
 45% { opacity: 0; }
 50% { opacity: 1; }
 55% { opacity: 0; }
 60% { opacity: 1; }
 65% { opacity: 0; }
 70% { opacity: 1; }
 75% { opacity: 0; }
 80% { opacity: 1; }
 85% { opacity: 0; }
 90% { opacity: 1; }
 95% { opacity: 0; }
`

const CardContainer = styled.div`
  height: 100%;
  padding: 30px;
  border-radius: 35px;
  min-height: 350px;
  font-size: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 14px solid ${props => props.theme.color.tertiaryLight};
  padding-bottom: 90px;
  hyphens: ${props => (props.type === 'default' ? 'auto' : 'none')};

  ${props => props.type === 'default' && css`
    color: ${props => props.theme.color.primary};
    background-color: white;
  `}

  ${props => props.type === 'utw' && css`
    color: white;
    background-color: ${props => props.theme.color.quartary};
  `}

  ${props => props.type === 'horse' && css`
    color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.secondary};
  `}

  ${props => props.type === 'win' && css`
    color: white;
    background-color: ${props => props.theme.color.tertiary};
  `}
`

const UtwImage = styled.img`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  animation-name: ${utwAnimation};
  animation-duration: 8s;
  animation-iteration-count: 1;
  opacity: 0;
`

const Card = ({text, type}) => {
  return <>
    <CardContainer type={type}>
      {text}
      {type === 'utw' && (
        <UtwImage src={utw} />
      )}
    </CardContainer>
  </>
}

export default Card
