import React, { useEffect } from 'react'
import styled from 'styled-components'

const Background = styled.div`
  background-color: ${props => props.theme.color.primary};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`

const Text = styled.span`
  display: block;
  text-align: left;
  color: white;
  margin-top: 12px;
  font-family: 'Arial';
  font-size: 14px;
`

const Home = () => {
    useEffect(() => {
        localStorage.removeItem('pmr-config');
    }, [])

    return <Background>
        <Container>
        <Text>De app is gereset</Text>
        </Container>
    </Background>
}

export default Home
