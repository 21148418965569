import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

import { Card, Button } from '../components'

import cross from '../assets/images/cross.png'
import beer from '../assets/images/beer.jpg'

import punjabi from '../assets/audio/punjabi-mc.mp3'
import horse from '../assets/audio/horse.mp3'

import { data } from '../data/cards'

const Background = styled.div`
  background-color: ${props => props.theme.color.primary};
  height: 100%;
`

const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`

const Title = styled.h1`
  color: ${props => props.theme.color.secondary};
  text-align: center;
  margin-top: 0;
`

const ButtonContainer = styled.div`
    margin-top: 40px;
`

const InfoButton = styled.button`
  width: 25px;
  height: 25px;
  position: absolute;
  border-radius: 50%;
  top: 32px;
  right: 20px;
  background-color: ${props => props.theme.color.secondary};
  color: ${props => props.theme.color.primary};
  border: 0;
  font-size: 20px;
  display: block;
  padding: 0;

`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.color.secondary};
  color: ${props => props.theme.color.primary};
  padding: 20px;
`

const ModalTitle = styled.h1`
  margin-bottom: 40px;
  margin-top: 0;
`

const ModalText = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 1.4;
`

const CrossImage = styled.img`
  width: 20px;
  position: absolute;
  right: 20px;
  top: 33px;
`

const CardContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
  position: relative;
`

const BeerImage = styled.img`
  width: 50px;
`

const BeerContainer = styled.div`
  display: flex;
  align-items: center;
  bottom: 30px;
  left: 35px;
  justify-content: center;
  position: absolute;

`

const Score = styled.span`
  display: block;
  font-size: 26px;
  margin-left: 10px;
  color: ${props => (props.type === 'default' || props.type === 'horse' ? props.theme.color.primary : 'white')};
`

const WarningModal = styled.div`  
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`

const WarningModalContainer = styled.div`
  max-width: 300px;
  background-color: ${props => props.theme.color.secondary};
  color: ${props => props.theme.color.primary};
  padding: 20px;
  border-radius: 35px;
  position: relative;

`

const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`

const Play = () => {
  const configDefault = {
    current: {
      text: '',
      type: 'default',
      score: 0,
    },
    cards: JSON.parse(atob(data)),
  }
  const [config, setConfig] = useState(configDefault)
  const [modalOpen, setModalOpen] = useState(false)
  const [isDisabled, setDisabled] = useState(false)
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const utwMusic = useRef(null);
  const horseMusic = useRef(null);

  useEffect(() => {
    const lsConfig = localStorage.getItem('pmr-config')

    if (lsConfig) {
      setConfig(JSON.parse(lsConfig))
    } else {
      nextCard(config)
    }
  }, [])

  const set = (data) => {
    setConfig(data)
    localStorage.setItem('pmr-config', JSON.stringify(data))
  }

  const nextCard = (configData) => {
    const count = configData.cards.length

    const randomNr = Math.floor(Math.random() * count);

    const newConfigCards = [...configData.cards]
    newConfigCards.splice(randomNr, 1);

    const newConfig = {
      current: configData.cards[randomNr],
      cards: newConfigCards,
    }

    if (count === 1) {
      const newConfig = {
        current: configData.cards[randomNr],
        cards: configDefault.cards,
      }

      set(newConfig)
      return
    }

    if (newConfig.current.type === 'utw') {
      utwMusic.current.play()
    }

    if (newConfig.current.type === 'horse') {
      horseMusic.current.play()
    }

    setDisabled(true)

    setTimeout(() => {
      setDisabled(false)
    }, 20000);

    set(newConfig)
  }

  return (
    <>
      <Background>
        <Container>
            <Title>
              {config.current.type === 'default' && 'Beeld uit:'}
              {config.current.type === 'utw' && 'Naughty'}
              {config.current.type === 'horse' && 'Pak die fles!'}
              {config.current.type === 'win' && 'Uitdelen'}
            </Title>
            <InfoButton onClick={() => setModalOpen(true)}>i</InfoButton>

            <CardContainer>
                <Card text={config.current.text} type={config.current.type} />
                <BeerContainer>
                  <BeerImage src={beer} />
                  <Score type={config.current.type}>{config.current.score}</Score>
                </BeerContainer>
            </CardContainer>

            <audio ref={utwMusic}>
              <source src={punjabi} type="audio/mpeg" />
            </audio>

            <audio ref={horseMusic}>
              <source src={horse} type="audio/mpeg" />
            </audio>

            <ButtonContainer>
              <Button disabled={isDisabled} onClick={() => isDisabled ? setWarningModalOpen(true) : nextCard(config)} variant="primary">Volgende kaart</Button>
            </ButtonContainer>
        </Container>
      </Background>
      {modalOpen && (
        <Modal>
          <CrossImage onClick={() => setModalOpen(false)} src={cross} />
          <ModalTitle>Spelregels</ModalTitle>
          <ModalText>
            <p>Ieder speelt voor zichzelf. De eerste persoon beeld uit wat er op het kaartje staat. Degene die het raadt mag het aantal slokken uitdelen dat onder het kaartje staat. De slokken mogen niet aan de uitbeelder uitgedeeld worden. Weigert de speler uit de beelden of lukt het de spelers niet om het te raden, dan neemt de speler zelf het aantal slokken dat bij het kaartje vermeld staat. Daarna wordt de telefoon doorgegeven aan de volgende persoon.</p>
            <p><strong>'Under the water' kaart</strong><br/>Bij het trekken van de 'Under the water' kaart moet de speler de rest van zijn/haar drankje atten.</p>
            <p><strong>'Deel 10 slokken uit' kaart</strong><br/>Bij het trekken van de 'Deel 10 slokken uit' kaart mag de speler 10 slokken uit delen aan de deelnemende spelers.</p>
            <p><strong>'Horse' kaart</strong><br/>Bij het trekken van de 'Horse' kaart spelen alle deelnemers Horse.</p>
          </ModalText>
        </Modal>
      )}

      {warningModalOpen && (
        <WarningModal>
          <ModalBackground onClick={() => setWarningModalOpen(false)} />
          <WarningModalContainer>
            <CrossImage onClick={() => setWarningModalOpen(false)} src={cross} />
            <ModalTitle>Wacht eens even</ModalTitle>
            <ModalText>
              {config.current.type === 'default' && `Niet gelukt? Voordat je verder gaat, neem eerst ${config.current.score} slokken!`}
              {config.current.type === 'horse' && `Ho ho, hebben jullie wel Horse gedaan?`}
              {config.current.type === 'utw' && `Eerst even een atje zetten voor je verder gaat!`}
              {config.current.type === 'win' && `Heb je al je slokken uitgedeeld? Laat ze eerst even drinken voordat je verder gaat`}
            </ModalText>
            <Button onClick={() => setWarningModalOpen(false)} variant="secondary">
              Okay
            </Button>
          </WarningModalContainer>
        </WarningModal>
      )}
    </>
  )
}

export default Play
