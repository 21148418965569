import Home from './pages/Home'
import Play from './pages/Play'
import Reset from './pages/Reset'
import ErrorPage from './pages/ErrorPage'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/play" element={<Play />}></Route>
          <Route path="/reset" element={<Reset />}></Route>

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App
