import React from 'react'
import styled from 'styled-components'

import { Button } from '../components'

import splash from '../assets/images/splash.png'

const Background = styled.div`
  background-color: ${props => props.theme.color.primary};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ButtonContainer = styled.div`
  margin-top: 40px;
`

const Container = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`

const Image = styled.img`
  width: 100%;
`

const Text = styled.span`
  display: block;
  text-align: left;
  color: white;
  margin-top: 12px;
  font-family: 'Arial';
  font-size: 14px;
`

const Home = () => {
  return <Background>
    <Container>
      <Image src={splash} />
      <ButtonContainer>
        <Button href="/play" variant="primary">Start het spel!</Button>
      </ButtonContainer>
      <Text>Let op! Dit spel is voor gevorderde Pictionary spelers. Betreed op eigen risico.</Text>
    </Container>
  </Background>
}

export default Home
