export const theme = {
    color: {
        primary: '#875bff',
        secondary: '#fde306',
        tertiary: '#59e3b3',
        tertiaryLight: 'rgba(89, 227, 179, 0.3)',
        quartary: '#e00c02',
        white: '#fff',
        lightGrey: '#F2F2F2',
        darkGrey: '#9a9a9a',
        black: '#000',
    },
}
