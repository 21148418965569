import React from 'react'
import styled, { css } from 'styled-components'

const ButtonElement = styled.a`
    padding: 19px 16px;
    border: none;
    display: flex;
    border-radius: 100px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    min-width: 130px;
    cursor: pointer;
    transition: background-color 300ms;
    text-decoration: none;
    color: ${props => props.variant === 'primary' ? props.theme.color.primary : 'white'};

    ${props => props.variant === 'primary' && css`
        color: ${props => props.theme.color.primary};
        background-color: ${props => props.theme.color.secondary};
    `}

    ${props => props.variant === 'secondary' && css`
        color: ${props => props.theme.color.secondary};
        background-color: ${props => props.theme.color.primary};
    `}

    ${props => props.disabled && css`
        color: ${props => props.theme.color.darkGrey};
        background-color: ${props => props.theme.color.lightGrey};
        opacity: 0.7;
    `}
`

const Text = styled.span`
    display: block;
    margin: 0 auto;
    font-size: 20px;
`

const Button = ({ href, children, variant, onClick, disabled }) => {
    return (
        <ButtonElement disabled={disabled} onClick={() => onClick()} href={href} variant={variant}>
            <Text>{children}</Text>
        </ButtonElement>
    )
}

export default Button